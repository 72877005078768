<template>
    <div class="project">
        项目展示
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>

</style>
