//import management from "./modules/management";
//import setup from "./modules/setup";

var api =  {
    //...management,
    //...setup,
}
export default api;

import {
    req,
    res
} from "@/utils/axios"
import axios from 'axios'

/**
 * 请求拦截器
 */

axios.interceptors.request.use(config => {
    config = req(config);
    return config;
}, error => {
    return Promise.reject(error);
});

/**
 * 响应拦截器
 */

axios.interceptors.response.use(response => {
    response = res(response);
    return response;
}, error => {
    return Promise.reject(error);
});
