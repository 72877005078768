import qs from 'qs'
import axios from 'axios'

/**
 * 配置
 */
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//axios.defaults.baseURL = 'http://192.168.0.127:8886/falifang/'

/**
 * 公共请求拦截器
 */

export const req = config => {
    if (config.data) { // 序列化
        //config.data = qs.stringify(config.data);
        if (!config.data.json) {
            config.data = qs.stringify(config.data);
        }
    }
    return config;
};

/**
 * 公共响应拦截器
 */

export const res = response => {
    return response;
};