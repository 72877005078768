import Vue from 'vue'
import Router from 'vue-router'

//网站维护升级时显示页面
import Build from './views/Build.vue'

//首页模块
//首页
import home from './views/Home/index.vue'


//个人简历模块
//个人简历
import resume from './views/resume/index.vue'


//项目展示模块
//项目展示
import project from './views/project/index.vue'


//联系沟通模块
//联系沟通
import contact from './views/contact/index.vue'



Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Build',
      component: Build
    },


    //首页
    /* {
      path: '/',
      name: 'home',
      component: home
    },


    //个人简历
    {
      path: '/resume',
      name: 'resume',
      component: resume
    },


    //项目展示
    {
      path: '/project',
      name: 'project',
      component: project
    },


    //联系沟通
    {
      path: '/contact',
      name: 'contact',
      component: contact
    }, */
  ]
})