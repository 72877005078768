<template>
  <div class="homeProject">
    <div class="wrap">
      <Tabs>
        <Tab-pane label="前后台网站">前后台网站</Tab-pane>
        <Tab-pane label="微信公众号">微信公众号</Tab-pane>
        <Tab-pane label="微信小程序">微信小程序</Tab-pane>
        <Tab-pane label="混合式App">混合式App</Tab-pane>
      </Tabs>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
};
</script>
<style lang="scss" scoped>
.homeProject{
    .ivu-tabs{
        padding-top: 80px;
        padding-bottom: 50px;
    }
}
</style>
