<template>
  <div class="home">
    <img alt="InConstruction" src="@/assets/img/404.png">
    <h1>网站正在建设中，敬请期待！</h1>
    <p class="recordNumber">Copyright 2018 liaojianhong.com All Rights Reserved <a href="https://beian.miit.gov.cn">粤ICP备19006539号</a></p>
  </div>
</template>

<script>
import headerNav from '@/components/headerNav';
export default {
  name: "home",
  components: {
    headerNav
  }
};
</script>
<style lang="scss" scoped>
.home{
  //width: 100%;
  text-align: center;
  padding: 10% 0;
  .recordNumber{
    position: absolute;
    bottom: 50px;
    width: 100%;
    font-size: 14px;
    color: #999999;
    a{
      color: #999999;
      cursor: pointer;
    }
  }
}
</style>


<!-- 


  server {
    #    listen       8000;
    #    listen       somename:8080;
    #    server_name  somename  alias  another.alias;

    #    location / {
    #        root   html;
    #        index  index.html index.htm;
    #    }
    #}
 -->