<template>
  <div class="homeBanner">
    <Carousel autoplay :autoplay-speed="4000" :height="700" trigger="hover">
      <Carousel-item>
        <div class="demo-carousel">
          <p class="WriteFont bannerTitle">仰望星空，脚踏实地</p>
          <img src="@/assets/img/home/banner/banner01.jpg">
        </div>
      </Carousel-item>
      <Carousel-item>
        <div class="demo-carousel">
          <p class="WriteFont bannerTitle">仰之弥高，钻之弥坚</p>
          <img src="@/assets/img/home/banner/banner02.jpg">
        </div>
      </Carousel-item>
      <Carousel-item>
        <div class="demo-carousel">
          <p class="WriteFont bannerTitle">学如不及，犹恐失之</p>
          <img src="@/assets/img/home/banner/banner03.jpg">
        </div>
      </Carousel-item>
    </Carousel>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.homeBanner {
  .demo-carousel {
    position: relative;
    width: 100%;
    height: 100%;
    .bannerTitle {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 40%;
      font-size: 60px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
