<template>
    <div class="home">
        <home-banner></home-banner>
        <home-resume></home-resume>
        <home-skill></home-skill>
        <home-project></home-project>
    </div>
</template>
<script>
import homeBanner from "@/components/Home/homeBanner" //走马灯组件
import homeResume from "@/components/Home/homeResume" //个人简介
import homeSkill from "@/components/Home/homeSkill" //个人技能
import homeProject from "@/components/Home/homeProject" //个人技能
export default {
    components:{
        homeBanner,
        homeResume,
        homeSkill,
        homeProject
    }
}
</script>
<style lang="scss" scoped>

</style>
