<template>
    <div class="resume">
        个人简历
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>

</style>
