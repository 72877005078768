import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//UI框架 iView
import iView from 'iview';
import 'iview/dist/styles/iview.css';

// 样式
import '@/assets/scss/public.scss'

//ajax
import { ajax } from './utils/ajax'

Vue.config.productionTip = false

Vue.use(iView);

Vue.prototype.ajax = ajax;

/* export default (context) => {
  // 服务器端ajax
  context.ajax = ajax
} */

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
