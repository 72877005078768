<template>
    <div class="contact">
        联系沟通
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>

</style>
