<template>
  <div class="headerNav">
    <div class="range MenuDiv">
      <Menu mode="horizontal" theme="light" active-name="1">
        <MenuItem name="1" class="menuLi" to="/">首页</MenuItem>
        <MenuItem name="2" class="menuLi" to="/resume">个人简历</MenuItem>
        <MenuItem name="3" class="menuLi" to="/project">项目展示</MenuItem>
        <MenuItem name="4" class="menuLi" to="/contact">联系沟通</MenuItem>
      </Menu>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.headerNav {
  //background-color: #515a6e;
  .MenuDiv {
    display: flex;
    justify-content: center;
    .menuLi {
      padding: 0 40px;
    }
  }
}
</style>
