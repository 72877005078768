<template>
    <div class="homeResume">
        <div class="wrap">
            <p class="homeResume_title">个人简介</p>
            <div class="content">
                <!-- <div class="left">
                    <img src="@/assets/img/portrait.jpg" />
                </div> -->
                <div class="right">
                    <p class="name">廖健宏</p>
                    <p class="Birthday">1996.02.09</p>
                    <p class="job">web前端工程师</p>
                    <p class="email">956810141@qq.com</p>
                    <p class="Motto">座右铭: 仰望星空 脚踏实地</p>
                    <!-- <p class="Message">梦想还是要有的，万一让自己用双手实现了呢！自己追求的梦想，让自己脚踏实地，一步一个脚印的去实现！</p> -->
                    <p class="Message">梦想还是要有的，万一实现了呢！</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.homeResume{
    .homeResume_title{
        width: 100%;
        text-align: center;
        font-size: 45px;
        margin-top: 80px;
    }
    .content{
        margin-top: 70px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        padding: 0 5px;
        /* .left{
            width: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 200px;
            }
        } */
        .right{
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            .name{
                font-size: 30px;
                font-weight: bold;
            }
            .Birthday{
                font-size: 20px;
                margin-bottom: 10px;
            }
            .job{
                font-size: 20px;
            }
            .email{
                font-size: 20px;
                margin-bottom: 10px;
            }
            .Motto{
                font-size: 20px;
                margin-top: 10px;
            }
            .Message{
                font-size: 20px;
                margin-top: 20px;
            }
        }
    }
}
</style>
