<template>
  <div class="homeSkill BGgray">
    <div class="wrap">
      <div class="layoutDiv">
        <div class="iconDiv">
          <img src="@/assets/img/home/skill/H5.png">
          <p>网站</p>
        </div>
        <div class="iconDiv">
          <img src="@/assets/img/home/skill/GZH.png">
          <p>微信公众号</p>
        </div>
        <div class="iconDiv">
          <img src="@/assets/img/home/skill/XCX.png">
          <p>微信小程序</p>
        </div>
        <div class="iconDiv">
          <img src="@/assets/img/home/skill/APP.png">
          <p>App</p>
        </div>
        <div class="iconDiv">
          <img src="@/assets/img/home/skill/Nodejs.png">
          <p>Node</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.homeSkill {
  .layoutDiv {
    display: flex;
    flex-direction: row;
    padding: 80px 0 50px 0;
    .iconDiv {
      display: flex;
      flex-direction: column;
      width: 20%;
      text-align: center;
      height: 218px;
      img{
        width: 150px;
        margin: 0 auto;
        transition: all 0.6s;
      }
      img:hover{
        //width: 200px;
        transform: scale(1.25);
        //margin: 0 auto;
      }
      p{
        margin-top: 20px;
        font-size: 18px;
      }
    }
  }
}
</style>

